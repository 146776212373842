import React from "react";

const Footer = () => {
  return (
    <div className=" relative flex flex-col text-center justify-center items-center gap-4 bg-black text-white ">
      <p className="py-8">
        Copyright © 2015 Hotel Triveni Darshan - Powered by gowappilyinfotech
      </p>
    </div>
  );
};

export default Footer;
